import React from "react";
import Header from "./../../components/Header/Header";
import OurInvestments from "./../OurInvestments/OurInvestments";
import "./Home.sass";
import HeadingVideo from "./animacjaBluHolding.webm";
import Boss from "./Boss.png";
import Scaler from "./scaler.png";
import Calendar from "./calendar.png";
import Coins from "./coins.png";
import Hourglass from "./hourglass.png";
import Investment from "./investment.png";
import Persons from "./persons.png";
import Logo360 from "./brand_logos/360_logo_color.svg"; // TODO not implemented
import Adshoot from "./brand_logos/ADSHOOT_logo_color.svg";
import BluOffice from "./brand_logos/Blu-Office_logo_color.svg";
import BluSkills from "./brand_logos/Blu-Professional-Skills-Institute_logo_color.svg";
import BluEM from "./brand_logos/Blu-eM_logo_color.svg";
import Blubb from "./brand_logos/BluBusinessClub_logo_color.svg";
import BluDrive from "./brand_logos/BluDrive_logo_color.svg"; // TODO not implemented
import Bluholding from "./brand_logos/BluHolding_logo_color.svg"; // TODO not implemented
import BluSerwer from "./brand_logos/BluSerwer_logo_color.svg";
import Blusign from "./brand_logos/BluSign_logo_color.svg";
import Blusoft from "./brand_logos/BluSoft_logo_color.svg";
import Bluspace from "./brand_logos/BluSpace_logo_color.svg";
import BlustreamAcad from "./brand_logos/BluStreamTV-Academy_logo_color.svg";
import Blustreamtv from "./brand_logos/BluStreamTV_logo_color.svg";
import BluTechLab from "./brand_logos/BluTechLab_logo_color.svg"; // TODO not implemented
import BydgoszczUczyMediacji from "./brand_logos/Bydgoszcz-Uczy-Mediacji_logo_color.svg"; // TODO not implemented
import EPulpit from "./brand_logos/e-Pulpit24_logo_color.svg";
import Zaufali from "./zaufali3.webp";
import Dell from "./dell_dark.webp";
import Microsoft from "./microsoft_dark.webp";
import IBM from "./ibm_dark.webp";
import AMD from "./AMD.png";
import Actina from "./Actina_czarne.png";
import Komputronik_gaming from "./komputronik-gaming.png";
import EKMA from "./EKMA.jpg";
import IFR from "./IFR-logo_2019-RGB-v1.png";
import Samsung from "./Samsung.png";
import Terra from "./terra-logo.png";
import Insert from "./insert.png";
import Subiekt from "./subiekt.png";
import BluHoldingLogo from "./BluHolding_logo_color.svg";
import BluMil from "./cybermil.png";
import Blugameshow from "./bgs.webp";
import BluOffice2 from "./bluoffice2.webp";

import { withTranslation } from "react-i18next";
import ProjectDescription from "../ProjectDescription/ProjectDescription";
import InvestPackages from "../InvestPackages/InvestPackages";
import Discuss from "../Discuss/Discuss";
import Documents from "../Documents/Documents";
import InvestList from "../InvestList/InvestList";
import Risks from "../Risks/Risks";
import BrandCard from "../../components/BrandCard/BrandCard";
import Footer from "../../components/Footer/Footer";
import InvestCard from "../../components/InvestCard/InvestCard";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

class Home extends React.Component {
  #projectDescRef = React.createRef();
  #investPackage = React.createRef();
  #discuss = React.createRef();
  #documents = React.createRef();
  #investList = React.createRef();
  #risks = React.createRef();
  #ourInvestments = React.createRef();
  reCaptchaRef = React.createRef();
  investorFormRef = React.createRef();

  state = {
    currentFrame: "projectDesc",
    userOnPage: true,
    // showInvestorForm:
    // window.location.href.split("/")[3] === "#investorForm" ? true : false,
    showInvestorForm: false,
    name: "",
    email: "",
    phone: "",
    msg: "Chcę zostać inwestorem proszę o kontakt.",
    recaptchaValue: "",
    recaptchaExpired: false,
    isMobileMenuOpen: false,
  };

  showInvestorForm = () => {
    this.setState({
      showInvestorForm: !this.state.showInvestorForm,
    });
  };

  handleInputPhone = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");

    this.setState({ phone: inputValue });
  };

  onChangeCaptcha = (value) => {
    if (value !== null) {
      this.sendForm(value);
    } else {
      Swal.fire({
        text: "Wystąpił błąd podczas walidacji reCaptcha",
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  executeCaptcha = () => {
    this.reCaptchaRef.current.execute();
  };

  sendForm = (recaptcha_value) => {
    let err = false;

    const name = this.state.name;
    const email = this.state.email;
    const phone = this.state.phone;
    const msg = this.state.msg;

    if (/\S+@\S+\.\S+/.test(email) === false) {
      document.querySelector("label[for=email]").style.color = "red";
      err = true;
    } else {
      document.querySelector("label[for=email]").style.color = "";
    }

    if (phone !== "") {
      if (phone.length < 8) {
        document.querySelector("label[for=phone]").style.color = "red";
        err = true;
      } else {
        document.querySelector("label[for=phone]").style.color = "";
      }
    } else {
      document.querySelector("label[for=phone]").style.color = "";
    }

    if (!err) {
      fetch(`https://bluholding.pl/sendmail/index.php`, {
        method: "POST",
        header: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          msg: msg,
          recaptcha_value: recaptcha_value,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            Swal.fire({
              text: res.msg,
              icon: "success",
              toast: true,
              timer: 2500,
              position: "top",
              showConfirmButton: false,
            });

            this.setState({ name: "" });
            this.setState({ email: "" });
            this.setState({ phone: "" });
            this.setState({ msg: "Chcę zostać inwestorem proszę o kontakt." });
          } else {
            Swal.fire({
              text: res.msg,
              icon: "error",
              toast: true,
              timer: 2500,
              position: "top",
              showConfirmButton: false,
            });
          }
        });
    } else {
      Swal.fire({
        text: "Niepoprawnie wypełniono formularz.",
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  componentDidMount() {
    document.title = "BluHolding";
    window.addEventListener("resize", this.#onResize);
    window.addEventListener("click", this.#onClick);
    document.addEventListener("mouseleave", () => {
      this.setState({ userOnPage: false });
    });
    document.addEventListener("mouseenter", () => {
      this.setState({ userOnPage: true });
    });
    window.addEventListener("hashchange", (event) => {
      if (this.state.userOnPage) return;
      this.#changeView(event.newURL.split("/")[3]);
    });
    const href = window.location.href.split("/")[0];
    if (this.state.currentFrame !== "projectDesc") {
      document.querySelector(".nav__indicator").style.background = "";
    }
    this.#changeView(window.location.href.split("/")[3]);

    if (window.location.href.split("/")[3] === "#investorForm") {
      this.setState({ showInvestorForm: true });
      this.investorFormRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.location.href.split("/")[3] === "#investorForm") {
      if (prevState.showInvestorForm === false) {
        this.setState({ showInvestorForm: true });
      }

      this.investorFormRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.#onResize);
    window.removeEventListener("click", this.#onClick);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="Home">
        <Header mainPageCallback={this.#changeView} />
        <div className="heading">
          <video
            src={HeadingVideo}
            autoPlay={true}
            controls={false}
            muted={true}
            playsInline={true}
          ></video>
        </div>
        <section className="section" id="o-nas">
          <div className="w100 flex justify-center">
            <h2>{t("whatIs")} BluHolding?</h2>
          </div>
          <div className="section__block__boss section__block--white">
            <div>
              <p>{t("content1par1")}</p>
            </div>
            <div className="boss">
              <img
                draggable={false}
                src={Boss}
                alt="Prezes Krzysztof Błaszkiewicz"
              />
              <p>
                <span className="text--handwritten">{t("boss")}</span>
              </p>
            </div>
          </div>
        </section>
        <section className="section--gray">
          <div className="w100 flex justify-center">
            <h2>BluHolding {t("inNumbers")}</h2>
          </div>
          <div className="section__block--transparent grid--6-3">
            <div className="card--small">
              <img src={Scaler} alt="Waga" />
              <p>
                <b>0%</b>
              </p>
              <p>{t("emissionProgress")}</p>
            </div>
            <div className="card--small">
              <img src={Calendar} alt="Kalendarz" />
              <p>
                <b>{t("d01012023")}</b>
              </p>
              <p>{t("startDate")}</p>
            </div>
            <div className="card--small">
              <img src={Hourglass} alt="Klepsydra" />
              <p>
                <b>{t("project")}</b>
              </p>
              <p>{t("inProgress")}</p>
            </div>
            <div className="card--small">
              <img src={Persons} alt="Inwestycje" />
              <p>
                <b>{t("infoSoon")}</b>
              </p>
              <p>{t("investments")}</p>
            </div>
            <div className="card--small">
              <img src={Investment} alt="Stopa zwrotu" />
              <p>
                <b>16,5%</b>
              </p>
              <p>{t("rate")}</p>
            </div>
            <div className="card--small">
              <img src={Coins} alt="Monety" />
              <p>
                <b>{t("infoSoon")}</b>
              </p>
              <p>{t("successTreshold")}</p>
            </div>
          </div>
        </section>
        <section className="section">
          <div
            className="section__center"
            id="investorForm"
            ref={this.investorFormRef}
          >
            <input
              type="button"
              className="button--primary"
              value={t("becomeAShareHolder")}
              onClick={this.showInvestorForm}
            />
          </div>
          {this.state.showInvestorForm && (
            <div className="section__form">
              <div>
                <label htmlFor="name">
                  <b>{t("formName")}</b>
                </label>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="email">
                  <b>{t("formEmail")}</b>
                </label>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="phone">
                  <b>{t("formPhone")}</b>
                </label>
                <input
                  type="text"
                  value={this.state.phone}
                  onChange={this.handleInputPhone}
                  maxLength={14}
                />
              </div>
              <div>
                <label htmlFor="msg">
                  <b>{t("formMsg")}</b>
                </label>
                <textarea
                  value={this.state.msg}
                  onChange={(e) => this.setState({ msg: e.target.value })}
                ></textarea>
              </div>
              <ReCAPTCHA
                style={{ zIndex: "999" }}
                sitekey="6LfiO-MoAAAAABMCQx4my5blBiYQ39r7A6pEgTpN"
                size="invisible"
                ref={this.reCaptchaRef}
                onChange={this.onChangeCaptcha}
              />
              <button className="button--primary" onClick={this.executeCaptcha}>
                {t("formBtn")}
              </button>
            </div>
          )}
        </section>
        <section className="section" id="menu">
          <div
            className="nav__menu-button--mobile"
            onClick={() => {
              this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
            }}
          >
            {this.state.isMobileMenuOpen
              ? t("menuMobileClose")
              : t("menuMobileOpen")}
          </div>
          <nav
            className={`nav ${
              this.state.isMobileMenuOpen ? "nav--open" : "nav--hidden"
            }`}
            onMouseMove={this.#onNavHover}
            onMouseLeave={this.#onNavHoverLeave}
            onClick={(event) => {
              this.#onNavOptionChange(event);
              this.setState({ isMobileMenuOpen: false });
            }}
          >
            <div className="nav__indicator--hover"></div>
            <div className="nav__indicator"></div>
            <div
              data-href="#opis-projektu"
              data-frame="projectDesc"
              ref={this.#projectDescRef}
              id="option--bold"
              className="nav__option--active"
            >
              {t("projectDesc")}
            </div>
            <div
              data-href="#pakiety-inwestorskie"
              data-frame="investPackage"
              ref={this.#investPackage}
              className="nav__option"
            >
              {t("investPackage")}
            </div>
            <div
              data-href="#faq"
              data-frame="discuss"
              ref={this.#discuss}
              className="nav__option"
            >
              {t("FAQ")}
            </div>
            <div
              data-href="#dokumenty"
              data-frame="documents"
              ref={this.#documents}
              className="nav__option"
            >
              {t("documents")}
            </div>
            <div
              data-href="#lista-inwestorow"
              data-frame="investList"
              ref={this.#investList}
              className="nav__option"
            >
              {t("investList")}
            </div>
            <div
              data-href="#czynniki-ryzyka"
              data-frame="risks"
              ref={this.#risks}
              className="nav__option"
            >
              {t("risks")}
            </div>
            <div
              data-href="#nasze-inwestycje"
              data-frame="ourInvestments"
              ref={this.#ourInvestments}
              className="nav__option"
            >
              {t("ourInvestments")}
            </div>
          </nav>
        </section>
        <section className="section">
          <div className="section__block section__block--white">
            {this.#frame(this.state.currentFrame)}
          </div>
        </section>
        <section className="section" id="nasze-marki">
          <div className="w100 flex justify-center">
            <h2>{t("ourBrands")}</h2>
          </div>
          <div className="logo">
            <img src={BluHoldingLogo} alt="BluHolding" />
          </div>
          <div className="our-brands">
            <BrandCard
              image={BluMil}
              imageAlt="e-pulpit24.pl"
              short={t("blumilShort")}
              content={t("blumilLong")}
            />
            <BrandCard
              image={EPulpit}
              imageAlt="e-pulpit24.pl"
              short={t("epulpitShort")}
              content={t("epulpitLong")}
            />
            <BrandCard
              image={Blusoft}
              imageAlt="blusoft"
              short={t("blusoftShort")}
              content={t("blusoftLong")}
            />
            <BrandCard
              image={BluSerwer}
              imageAlt="BluSerwer"
              short={t("bluserwerShort")}
              content={t("bluserwerLong")}
            />
            <BrandCard
              image={Blusign}
              imageAlt="BluSerwer"
              short={t("blusignShort")}
              content={t("blusignLong")}
            />
            <BrandCard
              image={Blustreamtv}
              imageAlt="BluStreamTV"
              short={t("blustreamtvShort")}
              content={t("blustreamtvLong")}
            />
            <BrandCard
              image={BlustreamAcad}
              imageAlt="BluStream Academy"
              short={t("blustreamacadShort")}
              content={t("blustreamacadLong")}
            />
            <BrandCard
              image={Adshoot}
              imageAlt="Adshoot"
              short={t("adshootShort")}
              content={t("adshootLong")}
            />
            <BrandCard
              image={Bluspace}
              imageAlt="Fundacja Bluspace"
              short={t("fundacjaShort")}
              content={t("fundacjaLong")}
            />
            <BrandCard
              image={Blugameshow}
              imageAlt="Blu Game Show"
              short={t("bgsShort")}
              content={t("bgsLong")}
            />
            <BrandCard
              image={Blubb}
              imageAlt="Blu Bussiness Club"
              short={t("bbcShort")}
              content={t("bbcLong")}
            />
            <BrandCard
              image={BluEM}
              imageAlt="BluEM"
              short={t("bemShort")}
              content={t("bemLong")}
            />
            <BrandCard
              image={BluOffice}
              imageAlt="BluOffice"
              short={t("bofficeShort")}
              content={t("bofficeLong")}
            />
            <BrandCard
              image={BluSkills}
              imageAlt="Blu Proffesional Skills Institute"
              short={t("bpsiShort")}
              content={t("bpsiLong")}
            />
            <BrandCard
              image={BluOffice2}
              imageAlt="BluOffice"
              short={t("bdriveShort")}
              content={t("bdriveLong")}
            />
          </div>
        </section>
        <section className="section" id="zaufali-nam">
          <div className="w100 flex justify-center">
            <h2>{t("trustedUs")}</h2>
          </div>
          <img src={Zaufali} className="w100" />
        </section>
        <section className="section" id="partnerzy">
          <div className="w100 flex justify-center">
            <h2>{t("partners")}</h2>
          </div>
        </section>
        <div className="baner">
          <img src={Dell} alt="Dell" />
          <img src={Microsoft} alt="Microsoft" />
          <img src={IBM} alt="IBM" />
          <img src={AMD} alt="AMD" />
          <img src={Actina} alt="Actina" />
          <img src={Komputronik_gaming} alt="Komputronik gaming" />
          <img src={EKMA} alt="EKMA" />
          <img src={IFR} alt="IFR" />
          <img src={Samsung} alt="Samsung" />
          <img src={Terra} alt="Terra" />
          <img src={Subiekt} alt="Subiekt" />
          <img src={Insert} alt="InsERT" />
        </div>
        <section className="section" id="wyroznienia">
          <div className="w100 flex justify-center">
            <h2>{t("awards")}</h2>
          </div>
          <div className="section__block section__block--white awards">
            <a href="/static/pdf/wyr1.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/wyr1.jpg"
                alt="wyr1"
              />
            </a>
            <a href="/static/pdf/wyr2.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr2.jpg"
                alt="wyr2"
              />
            </a>
            <a href="/static/pdf/wyr3.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr3.jpg"
                alt="wyr3"
              />
            </a>
            <a href="/static/pdf/wyr4.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr4.jpg"
                alt="wyr4"
              />
            </a>
            <a href="/static/pdf/wyr5.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr5.jpg"
                alt="wyr5"
              />
            </a>
            <a href="/static/pdf/wyr6.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr6.jpg"
                alt="wyr6"
              />
            </a>
            <a href="/static/pdf/wyr7.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr7.jpg"
                alt="wyr7"
              />
            </a>
            <a href="/static/pdf/wyr8.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr8.jpg"
                alt="wyr8"
              />
            </a>
            <a href="/static/pdf/wyr9.pdf" target="_blank">
              <img
                className="document-thumbnail"
                src="/static/images/wyr9.jpg"
                alt="wyr9"
              />
            </a>
          </div>
        </section>
        <section className="section" id="referencje">
          <div className="w100 flex justify-center">
            <h2>{t("recommendations")}</h2>
          </div>
          <div className="section__block section__block--white awards">
            <a href="/static/pdf/ref1.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref1.jpg"
                alt="ref1"
              />
            </a>
            <a href="/static/pdf/ref2.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref2.jpg"
                alt="ref2"
              />
            </a>
            <a href="/static/pdf/ref3.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref3.jpg"
                alt="ref3"
              />
            </a>
            <a href="/static/pdf/ref4.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref4.jpg"
                alt="ref4"
              />
            </a>
            <a href="/static/pdf/ref5.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref5.jpg"
                alt="ref5"
              />
            </a>
            <a href="/static/pdf/ref6.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref6.jpg"
                alt="ref6"
              />
            </a>
            <a href="/static/pdf/ref8.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref8.jpg"
                alt="ref8"
              />
            </a>
            <a href="/static/pdf/ref9.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref9.jpg"
                alt="ref9"
              />
            </a>
            <a href="/static/pdf/ref10.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref10.jpg"
                alt="ref10"
              />
            </a>
            <a href="/static/pdf/ref11.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref11.jpg"
                alt="ref11"
              />
            </a>
            <a href="/static/pdf/ref12.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref12.jpg"
                alt="ref12"
              />
            </a>
            <a href="/static/pdf/ref13.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref13.jpg"
                alt="ref13"
              />
            </a>
            <a href="/static/pdf/ref14.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref14.jpg"
                alt="ref14"
              />
            </a>
            <a href="/static/pdf/ref15.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref15.jpg"
                alt="ref15"
              />
            </a>
            <a href="/static/pdf/ref16.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref16.jpg"
                alt="ref16"
              />
            </a>
            <a href="/static/pdf/ref17.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref17.jpg"
                alt="ref17"
              />
            </a>
            <a href="/static/pdf/ref18.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref18.jpg"
                alt="ref18"
              />
            </a>
            <a href="/static/pdf/ref19.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref19.jpg"
                alt="ref19"
              />
            </a>
            <a href="/static/pdf/ref20.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref20.jpg"
                alt="ref20"
              />
            </a>
            <a href="/static/pdf/ref21.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref21.jpg"
                alt="ref21"
              />
            </a>
            <a href="/static/pdf/ref22.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref22.jpg"
                alt="ref22"
              />
            </a>
            <a href="/static/pdf/ref24.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref24.jpg"
                alt="ref24"
              />
            </a>
            <a href="/static/pdf/ref25.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref25.jpg"
                alt="ref25"
              />
            </a>
            <a href="/static/pdf/ref26.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref26.jpg"
                alt="ref26"
              />
            </a>
            <a href="/static/pdf/ref27.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref27.jpg"
                alt="ref27"
              />
            </a>
            <a href="/static/pdf/ref29.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref29.jpg"
                alt="ref29"
              />
            </a>
            <a href="/static/pdf/ref31.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref31.jpg"
                alt="ref31"
              />
            </a>
            <a href="/static/pdf/ref32.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref32.jpg"
                alt="ref32"
              />
            </a>
            <a href="/static/pdf/ref33.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref33.jpg"
                alt="ref33"
              />
            </a>
            <a href="/static/pdf/ref34.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref34.jpg"
                alt="ref34"
              />
            </a>
            <a href="/static/pdf/ref35.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref35.jpg"
                alt="ref35"
              />
            </a>
            <a href="/static/pdf/ref36.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref36.jpg"
                alt="ref36"
              />
            </a>
            <a href="/static/pdf/ref37.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref37.jpg"
                alt="ref37"
              />
            </a>
            <a href="/static/pdf/ref38.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref38.jpg"
                alt="ref38"
              />
            </a>
            <a href="/static/pdf/ref39.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/ref39.jpg"
                alt="ref39"
              />
            </a>
          </div>
        </section>
        <section className="section" id="csr">
          <div className="w100 flex justify-center">
            <h2>{t("csr")}</h2>
          </div>
          <div className="section__block section__block--white awards">
            <a href="/static/pdf/csr1.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr1.jpg"
                alt="csr1"
              />
            </a>
            <a href="/static/pdf/csr2.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr2.jpg"
                alt="csr2"
              />
            </a>
            <a href="/static/pdf/csr3.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr3.jpg"
                alt="csr3"
              />
            </a>
            <a href="/static/pdf/csr4.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr4.jpg"
                alt="csr4"
              />
            </a>
            <a href="/static/pdf/csr5.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr5.jpg"
                alt="csr5"
              />
            </a>
            <a href="/static/pdf/csr6.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr6.jpg"
                alt="csr6"
              />
            </a>
            <a href="/static/pdf/csr7.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr7.jpg"
                alt="csr7"
              />
            </a>
            <a href="/static/pdf/csr8.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr8.jpg"
                alt="csr8"
              />
            </a>
            <a href="/static/pdf/csr9.pdf" target="_blank">
              <img
                className="document-thumbnail "
                src="/static/images/csr9.jpg"
                alt="csr9"
              />
            </a>
          </div>
        </section>
        <Footer
          mainPageCallback={this.#changeView}
          scrollInvestForm={this.#scrollToInvestorForm}
        />
      </div>
    );
  }

  #onResize = () => {
    const lastHoverElem = document.querySelector(".nav__option--active");
    const offsets = document.querySelector(".nav").getBoundingClientRect();
    const indicator = document.querySelector(".nav__indicator");
    const hoverIndicator = document.querySelector(".nav__indicator");
    indicator.style.left = `${
      lastHoverElem.getBoundingClientRect().left - offsets.left
    }px`;
    indicator.style.top = `${
      lastHoverElem.getBoundingClientRect().top - offsets.top
    }px`;
    indicator.style.width = `${lastHoverElem.getBoundingClientRect().width}px`;
    hoverIndicator.style.left = `${
      lastHoverElem.getBoundingClientRect().left - offsets.left
    }px`;
    hoverIndicator.style.top = `${
      lastHoverElem.getBoundingClientRect().top - offsets.top
    }px`;
    hoverIndicator.style.width = `${
      lastHoverElem.getBoundingClientRect().width
    }px`;
  };

  #onNavHover = (event) => {
    const offsets = document.querySelector(".nav").getBoundingClientRect();
    if (
      !(
        event.target.className === "nav__option" ||
        event.target.className === "nav__option--active"
      )
    )
      return;
    const indicator = document.querySelector(".nav__indicator--hover");
    indicator.style.background = "#20409e";
    indicator.style.left = `${
      event.target.getBoundingClientRect().left - offsets.left
    }px`;
    indicator.style.top = `${
      event.target.getBoundingClientRect().top - offsets.top
    }px`;
    indicator.style.width = `${event.target.getBoundingClientRect().width}px`;
  };

  #onNavHoverLeave = () => {
    const indicator = document.querySelector(".nav__indicator--hover");
    indicator.style.background = "";
  };

  #onNavOptionChange = (event) => {
    const offsets = document.querySelector(".nav").getBoundingClientRect();
    if (
      !(
        event.target.className === "nav__option" ||
        event.target.className === "nav__option--active"
      )
    )
      return;

    document.querySelector(".nav__option--active").className = "nav__option";
    const indicator = document.querySelector(".nav__indicator");
    indicator.style.left = `${
      event.target.getBoundingClientRect().left - offsets.left
    }px`;
    indicator.style.top = `${
      event.target.getBoundingClientRect().top - offsets.top
    }px`;
    indicator.style.width = `${event.target.getBoundingClientRect().width}px`;
    event.target.className = "nav__option--active";
    this.setState({ currentFrame: event.target.getAttribute("data-frame") });
    window.location.href = event.target.getAttribute("data-href");
  };

  #frame = (componentName) => {
    if (componentName === "projectDesc") return <ProjectDescription />;
    else if (componentName === "investPackage") return <InvestPackages />;
    else if (componentName === "discuss") return <Discuss />;
    else if (componentName === "documents") return <Documents />;
    else if (componentName === "investList") return <InvestList />;
    else if (componentName === "risks") return <Risks />;
    else if (componentName === "ourInvestments") return <OurInvestments />;
    else return null;
  };

  #onClick = (event) => {
    let node = event.target;
    if (Array.from(node.classList).includes("BrandCard--expanded")) return;
    node = node.parentElement;
    while (
      node &&
      !Array.from(node.classList).includes("BrandCard--expanded")
    ) {
      node = node.parentElement;
    }
    if (!!node) return;
    // console.log(!!node)

    document.querySelectorAll(".BrandCard--expanded").forEach((elem) => {
      elem.classList.toggle("BrandCard--expanded");
    });
  };

  #changeView = (path) => {
    if (path === "#opis-projektu")
      this.#checkRefAndGo(this.#projectDescRef, path);
    else if (path === "#pakiety-inwestorskie")
      this.#checkRefAndGo(this.#investPackage, path);
    else if (path === "#faq") this.#checkRefAndGo(this.#discuss, path);
    else if (path === "#dokumenty") this.#checkRefAndGo(this.#documents, path);
    else if (path === "#lista-inwestorow")
      this.#checkRefAndGo(this.#investList, path);
    else if (path === "#czynniki-ryzyka")
      this.#checkRefAndGo(this.#risks, path);
    else if (path === "#nasze-inwestycje")
      this.#checkRefAndGo(this.#ourInvestments, path);
  };

  #scrollToInvestorForm = (data) => {
    if (data) {
      this.setState({ showInvestorForm: true });
      this.investorFormRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  #checkRefAndGo = (ref, path) => {
    const current = ref.current;
    if (!current) return;
    current.click();
    window.location.href = "/#menu";
  };
}

export default withTranslation()(Home);
